<template>
  <!-- <div id="invoice"> -->
  <div v-if="show" class="invoice-box" id="invoice" :style="{'padding': '30px 15px','background-color': '#ffffff',
         'direction': (lang == 'ar'? 'rtl': 'ltr'), 'text-align': (lang == 'ar'? 'right': 'left') }">
    <table :style="{'text-align': (lang == 'ar'? 'right': 'left')}" cellpadding="0" cellspacing="0">
      <tr class="top">
        <td class="title">
          <h3><b>{{ $t('MENU.packing_slip') }}</b></h3>
        </td>
        <td :style="{'text-align': (lang == 'ar'? 'left': 'right')}" colspan="2">
          {{ data.customer_name }}<br>
          {{ data.mobile }}<br>
          {{ data.email }}
        </td>
      </tr>
      <hr>
      <tr class="top">
        <td>
          <h3 class="padd">{{ $t('invoice_sales_packing.invoice_number') }} #{{ idEditing }}</h3>
        </td>
      </tr>

      <tr class="information">
        <td>
          <b>{{ $t('invoice_sales_packing.ship_to') }}.</b><br/> {{ data.customer_name }}<br/> {{ data.email }} <br> {{ data.mobile }}
        </td>

        <td :style="{'text-align': (lang == 'ar'? 'left': 'right')}" colspan="2">
          <b>{{ $t('invoice_sales_packing.bill_to') }}.</b><br/> John Doe<br/> john@example.com
        </td>
      </tr>

      <tr class="heading">
        <td class="border">{{ $t('invoice_sales_packing.description') }}</td>
        <td class="border">{{ $t('invoice_sales_packing.quantity') }}</td>
      </tr>


      <tr class="item" v-for="(row, index) in items_list" :key="index">
        <td class="border">{{ row.description }}</td>
        <td class="border">{{ row.qty }}</td>
      </tr>

      <tr class="heading">
        <td class="border color">{{ $t('invoice_sales_packing.total') }}</td>
        <td class="border">{{ sumQty }}</td>
      </tr>

    </table>
    <br>
    <div :style="{'text-align': (lang == 'ar'? 'left': 'right')}">
      <vue-barcode :value="data.invoice_code" :options="{ displayValue: true }"></vue-barcode>
    </div>

  </div>
  <!-- </div> -->
</template>

<script>
import {jsPDF} from "jspdf";
import html2canvas from "html2canvas";
import ApiService from "@/core/services/api.service";

import VueBarcode from '@chenfengyuan/vue-barcode';
import jsPdfExport from "@/core/config/jsPdfExport";

export default {
  name: "packing-slip-invoice",
  components: {VueBarcode},

  data() {
    return {
      lang: this.$i18n.locale,
      mainRoute: 'sales/general_sales',
      key: 'SalesInvoice',
      data: {
        customer_id: null,
        customer_name: null,
        email: null,
        mobile: null,
        invoice_code: null,
      },
      items_list: [],
      idEditing: this.$route.params.sales_invoice_id ? this.$route.params.sales_invoice_id : null,
      type: this.$route.params.type ? this.$route.params.type : null,
      sumQty: 0,
      show: false,
    }
  },
  methods: {
    printCustomerInvoice() {
      // let element = document.getElementById('invoice');
      // let pdf = jsPDF("p", "pt", "a4");
      // // let check_lang = this.lang == 'ar'? 'arabic': 'english';
      // html2canvas(element).then(canvas => {
      //     let image = canvas.toDataURL('image/png', 1.0);
      //     pdf.addImage(image, 'PNG', 40, 15);
      //     // pdf.save(`${ 'packing slip '}${check_lang}${'.pdf'}`);
      //     pdf.autoPrint();
      //     let _url = pdf.output("bloburl");
      //     location.href = _url;
      // });
      let _id = 'invoice';
      this.exportPDF(_id);
    },
    exportPDF(_id) {
      let that = this;
      // let pdf = jsPDF("p", "px", 'a4');
      //
      // const AmiriRegular = pdf.loadFile("https://rawcdn.githack.com/MrRio/jsPDF/cbc85b4bb66d5c1a2d50d199e8bf33836f2f12d7/test/reference/Amiri-Regular.ttf", true);
      // pdf.addFileToVFS("Amiri-Regular.ttf", AmiriRegular);
      //
      // pdf.addFont("Amiri-Regular.ttf", "Amiri", "normal");
      let pdf = jsPdfExport("p", "px", 'a4');
      pdf.html(document.getElementById(_id), {
        html2canvas: {
          scale: 0.5,
        },
        callback: function (pdf) {
          if (that.type == 'pdf') {
            pdf.save('invoice');
          } else {
            pdf.autoPrint();
            pdf.output('dataurlnewwindow');
          }

          // var iframe = document.createElement('iframe');
          // iframe.setAttribute('style', 'position:absolute;top:0;right:0;height:100%; width:600px');
          // document.body.appendChild(iframe);
          // iframe.src = pdf.output('datauristring');
        }
      });
    },
    async getData() {
      let _id = this.idEditing;
      await ApiService.get(`${this.mainRoute}/${_id}`).then((response) => {

        this.data.customer_id = response.data.data.customer_id;
        this.data.customer_name = response.data.data.customer_name;
        this.data.email = response.data.data.customer.email;
        this.data.mobile = response.data.data.customer.mobile;
        this.data.invoice_code = response.data.data.invoice_code;
        this.items_list = response.data.data.items_list;
        // get total coun items lenth
        this.sumQty = this.items_list.length;
        this.show = true;
      });
    },
  },
  mounted() {
    let promise = this.getData();
    Promise.all([promise]).then(() => {
      this.printCustomerInvoice();
    });
    // window.print();
  }
}
</script>

<style>
#invoice {
  font-family: "Amiri";
  width: 235mm;
}
canvas {
  height: 100;
}

.invoice-box {
  background-color: #fff;
  margin: auto;
  padding: 30px;
  border: 1px solid #fff;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
  color: #000;
}

.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}

.invoice-box table td {
  /* padding: 5px; */
  vertical-align: top;
}

.invoice-box table tr td:nth-child(2) {
  text-align: right;
}

.invoice-box table tr td:nth-child(3) {
  text-align: right;
}

.invoice-box table tr.top td.title {
  font-size: 45px;
  line-height: 45px;
  color: #000;
}

.padd {
  padding-bottom: 100px;

}

.invoice-box table tr.information td {
  padding-bottom: 40px;
}

.invoice-box table tr.heading td {
  border: 1px solid #000 !important;
  background: #CAD3C8;
  border-bottom: 1px solid #fff;
  font-weight: bold;
}

.border {
  border: 1px solid #000 !important;
  padding: 5px;
}

.color {
  background-color: #CAD3C8;
}

.invoice-box table tr.details td {
  padding-bottom: 20px;
}

.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .invoice-box table tr.information td {
    width: 100%;
    display: block;
    text-align: center;
  }
}

/** RTL **/

.invoice-box.rtl {
  direction: rtl;
  font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
}

.invoice-box.rtl table {
  text-align: right;
}

.invoice-box.rtl table tr td:nth-child(2) {
  text-align: left;
}
</style>